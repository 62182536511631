import React from 'react';
import Layout from '../layout';
import { PartnerForm } from '../views/features';

const PartnerPage = ({
  pageContext: { data = {}, metaData = {}, menus = [] },
  location,
}) => {
  return (
    <Layout SEOTitle="Request Partnership" metaData={metaData} menus={menus}>
      <PartnerForm
        location={location}
        sections={data.sections}
        metaData={metaData}
      />
    </Layout>
  );
};

export default PartnerPage;
